import "./bootstrap";
import "../css/app.css";

import { createRoot, hydrateRoot } from "react-dom/client";
import { createInertiaApp } from "@inertiajs/react";
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers";

import { PropsWithChildren } from "react";
import { Toaster } from "react-hot-toast";

import * as Sentry from "@sentry/react";

const appName = import.meta.env.VITE_APP_NAME || "Laravel";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient();

const Providers = ({ children }: PropsWithChildren) => {
    return (
        <>
            <QueryClientProvider client={queryClient}>
                {children}
            </QueryClientProvider>
            <Toaster />
        </>
    );
};

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) =>
        resolvePageComponent(
            `./Pages/${name}.tsx`,
            import.meta.glob("./Pages/**/*.tsx")
        ),
    setup({ el, App, props }) {
        const sentryDns = import.meta.env.VITE_SENTRY_DNS;
        if (sentryDns) {
            Sentry.init({
                dsn: sentryDns,
                integrations: [
                    // Sentry.browserTracingIntegration(),
                    // Sentry.replayIntegration(),
                ],
                // Tracing
                tracesSampleRate: 1.0, //  Capture 100% of the transactions
                // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
                tracePropagationTargets: ["localhost", /^https:\/\/kollo\.io/],
                // Session Replay
                replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
                replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
            });
            console.log("Sentry initialized", sentryDns);
        }

        if (import.meta.env.DEV) {
            createRoot(el).render(<Providers>{<App {...props} />}</Providers>);
            return;
        }

        hydrateRoot(el, <Providers>{<App {...props} />}</Providers>);
    },
    progress: {
        color: "rgba(var(--color-primary))",
    },
});
